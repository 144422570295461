.spesifikasi-property-dekstop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 136px;
  margin-bottom: 136px;

  .header-spesifikasi-property {
    width: 460px;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #232323;
  }

  .content-wrapper {
    max-width: 360px;
    width: 100%;
    .section-spesifikasi-property {
      width: 100%;
      .field-spesifikasi {
        margin-bottom: 28px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          &:first-child{
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #4f4f4f;
          }
          &:last-child{
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #232323;
            .satuan {
              color: #a7a7a7;
            }
          }
        }
      }
    }
  }
}
