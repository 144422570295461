.dekstop-marketing-agent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  align-items: center;
  position: relative;
  .bg-c21{
    
  }
  .marketing-info-wrapper {
    .header {
      margin-bottom: 24px;
      border-radius: 8px;
      border: 1px solid #2356a6;
      display: inline-block;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      span {
        color: #2356a6;
        font-size: 17px;
        font-style: normal;
        line-height: 22px;
      }
    }

    .name {
      color: #2356a6;
      margin-bottom: 8px;
      font-size: 50px;
      font-style: normal;
      font-family: "SF Pro Display SemiBold";
      line-height: normal;
      text-decoration: underline;
      cursor: pointer;
      display: block;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e9e9;
      .level {
        color: #232323;
        font-size: 17px;
        font-style: normal;
        font-family: "SF Pro Display SemiBold";
        line-height: 22px;
        letter-spacing: -0.43px;
      }
      svg {
        width: 8px;
        height: 8px;
        margin: 0 16px;
      }
      .company {
        font-size: 15px;
        line-height: 24px;
        color: #4f4f4f;
      }
    }

    .phone-wrapper {
      display: flex;
      align-items: center;

      .cbtn-whatsapp {
        display: flex;
        align-items: center;
        background: #2356a6;
        padding: 16px 24px;
        cursor: pointer;
        margin-right: 20px;
        .logo-wa {
          margin-right: 8px;
        }
        span {
          font-size: 17px;
          line-height: 22px;
          color: #ffffff;
        }
      }

      .wrapper-logo-info {
        display: flex;
        width: 260px;

        svg {
          margin-right: 8px;
          width: 40px;
          height: 40px;
        }
        span {
          color: #7b7b7b;
          font-size: 11px;
          font-style: normal;
          line-height: 13px;
          letter-spacing: 0.06px;
        }
      }
    }
  }

  .wrapper-ma-image {
    width: 424px;
    height: 668.159px;
    display: flex;
    justify-content: center;

    .ma-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
