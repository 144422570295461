.spesifikasi-rumah {
  margin-bottom: 40px;
  border-bottom: 1px solid #e9e9e9;
  .header-spesifikasi-rumah {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.23px;
    color: #232323;
    margin-bottom: 24px;
    padding: 0 24px;
  }

  .section-spesifikasi-rumah {
    border-bottom: 1px dashed #e9e9e9;
    margin-bottom: 24px;
    padding: 0 24px;
    &:last-child {
      margin-bottom: 40px;
      border: unset;
    }
    .field-spesifikasi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      span {
        &:first-child {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.23px;
          color: #7b7b7b;
        }
        &:last-child {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.43px;
          color: #232323;
        }

        .satuan {
          color: #7b7b7b;
          sup{
            color: inherit;
          }
        }
      }
    }
  }
}
