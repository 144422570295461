// Margin top
.mt-a{
  margin-top: 0;
}
.mt-b{
  margin-top: 4px;
}
.mt-c{
  margin-top: 8px;
}
.mt-d{
  margin-top: 12px;
}
.mt-e{
  margin-top: 16px;
}
.mt-f{
  margin-top: 20px;
}
.mt-g{
  margin-top: 24px;
}
.mt-h{
  margin-top: 28px;
}
.mt-i{
  margin-top: 32px;
}


// Margin Right
.mr-a{
  margin-right: 0;
}
.mr-b{
  margin-right: 4px;
}
.mr-c{
  margin-right: 8px;
}
.mr-d{
  margin-right: 12px;
}
.mr-e{
  margin-right: 16px;
}
.mr-f{
  margin-right: 20px;
}
.mr-g{
  margin-right: 24px;
}
.mr-h{
  margin-right: 28px;
}
.mr-i{
  margin-right: 32px;
}



// Margin bottom
.mb-a{
  margin-bottom: 0;
}
.mb-b{
  margin-bottom: 4px;
}
.mb-c{
  margin-bottom: 8px;
}
.mb-d{
  margin-bottom: 12px;
}
.mb-e{
  margin-bottom: 16px;
}
.mb-f{
  margin-bottom: 20px;
}
.mb-g{
  margin-bottom: 24px;
}
.mb-h{
  margin-bottom: 28px;
}
.mb-i{
  margin-bottom: 32px;
}


// Margin left
.ml-a{
  margin-left: 0;
}
.ml-b{
  margin-left: 4px;
}
.ml-c{
  margin-left: 8px;
}
.ml-d{
  margin-left: 12px;
}
.ml-e{
  margin-left: 16px;
}
.ml-f{
  margin-left: 20px;
}
.ml-g{
  margin-left: 24px;
}
.ml-h{
  margin-left: 28px;
}
.ml-i{
  margin-left: 32px;
}


// Margin horizontal
.mh-a{
  margin-left: 0;
  margin-right: 0;
}
.mh-b{
  margin-left: 4px;
  margin-right: 4px;
}
.mh-c{
  margin-left: 8px;
  margin-right: 8px;
}
.mh-d{
  margin-left: 12px;
  margin-right: 12px;
}
.mh-e{
  margin-left: 16px;
  margin-right: 16px;
}
.mh-f{
  margin-left: 20px;
  margin-right: 20px;
}
.mh-g{
  margin-left: 24px;
  margin-right: 24px;
}
.mh-h{
  margin-left: 28px;
  margin-right: 28px;
}
.mh-i{
  margin-left: 32px;
  margin-right: 32px;
}


// Margin vertical
.mv-a{
  margin-top: 0;
  margin-bottom: 0;
}
.mv-b{
  margin-top: 4px;
  margin-bottom: 4px;
}
.mv-c{
  margin-top: 8px;
  margin-bottom: 8px;
}
.mv-d{
  margin-top: 12px;
  margin-bottom: 12px;
}
.mv-e{
  margin-top: 16px;
  margin-bottom: 16px;
}
.mv-f{
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv-g{
  margin-top: 24px;
  margin-bottom: 24px;
}
.mv-h{
  margin-top: 28px;
  margin-bottom: 28px;
}
.mv-i{
  margin-top: 32px;
  margin-bottom: 32px;
}


// Margin horizontal vertical
.mhv-a{
  margin: 0;
}
.mhv-b{
  margin: 4px;
}
.mhv-c{
  margin: 8px;
}
.mhv-d{
  margin: 12px;
}
.mhv-e{
  margin: 16px;
}
.mhv-f{
  margin: 20px;
}
.mhv-g{
  margin: 24px;
}
.mhv-h{
  margin: 28px;
}
.mhv-i{
  margin: 32px;
}
