.detail-listing-dekstop-image-wrapper {
  width: 100%;
  max-width: 360px;
  height: 360px;
  position: relative;
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .thumbnails {
    .main-thumbnail {
      box-shadow: 0px 1.1733332872390747px 2.3466665744781494px 0px rgba(35, 35, 35, 0.06);
      border-radius: 2px;
      border: 1.173px solid #e9e9e9;
      width: 360px;
      height: 360px;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      justify-content: center;
      .main-image,
      video {
        border-radius: 2px;
        border: 1.173px solid #e9e9e9;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .fullscreen {
        video {
          object-fit: contain;
        }
      }
      .pause-btn {
        border-radius: 80px;
        background: rgba(35, 35, 35, 0.6);
        display: flex;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        bottom: 19px;
        span {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          line-height: 22px;
        }
        svg {
          width: 20px;
          height: 20px;
          margin-left: 4px;
        }
      }
    }
    .other-thumbnail {
      display: flex;
      justify-content: center;
      .image {
        cursor: pointer;
        margin-right: 16px;
        width: calc((360px / 3) - 12px);
        height: calc((360px / 3) - 12px);
        border-radius: 2px;
        border: 1.173px solid #e9e9e9;

        img {
          border: 1.173px solid #e9e9e9;
          border-radius: 2px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .lihat-foto-lainnya {
            opacity: 0.8;
            background: #232323;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;

            p {
              width: 90px;
              text-align: center;
              font-size: 15px;
              font-style: normal;
              line-height: 20px;
              letter-spacing: -0.23px;
            }
          }
        }
      }
    }
  }

  .btn-lihat-foto-detail {
    position: absolute;
    bottom: 24px;
    right: 24px;
    background: #ffffff;
    border: 1px solid #232323;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;

    .template-img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #232323;
    }
  }
}

.detail-listing-dekstop-images-modal-content {
  width: 100vw;
  height: 100vh;
  background: #000000;
  border: 1px solid #e9e9e9;

  display: flex;
  justify-content: center;
  align-items: center;
  .detail-listing-modal-content {
    width: 100%;
    max-width: 758px;
    .tutup-btn-modal {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;
      .tutup-modal-btn {
        display: flex;
        align-items: center;
        padding: 16px 12px;
        border: 1px solid #ffffff;
        background: #000000;
        cursor: pointer;
        .tutup-icon-svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
    .detail-listing-image-slider {
      background: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 758px;
      .arrow {
        width: 40px;
        height: 40px;
        cursor: pointer;
        visibility: hidden;
      }
      .custom-arrow-left,
      .custom-arrow-right {
        &.visible {
          visibility: visible;
        }
      }
      .modal-images-body {
        width: 100%;
        height: 517px;
        .image-wrapper {
          width: 100%;
          height: 100%;
          display: flex !important;
          justify-content: center;
        }
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          max-width: 560px;
          max-height: 517px;
        }
        .slick-arrow {
          width: 40px;
          height: 40px;
        }
        .slick-prev {
          left: -5px;
        }
        .slick-next {
          right: -5px;
        }
        .slick-disabled {
          display: none !important;
        }
      }
    }
    .detail-listing-pagination {
      margin-top: 57px;
      p {
        text-align: center;
        margin: 0;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        color: #ffffff;
        span {
          color: #a7a7a7;
        }
      }
    }
  }
}

.detail-listing-dekstop-video-modal-content {
  width: 100vw;
  height: 100vh;
  background: #000000;
  border: 1px solid #e9e9e9;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .tutup-btn-modal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    max-width: 758px;

    .tutup-modal-btn {
      display: flex;
      align-items: center;
      padding: 16px 12px;
      border: 1px solid #ffffff;
      background: #000000;
      cursor: pointer;
      .tutup-icon-svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
      span {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
}
