.detail-listing-judul {
  margin-top: 46px;
  padding: 0 24px;
  .id-listing-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .id-listing {
      font-family: "SF Pro Display Regular";
      font-size: 13px;
      line-height: 18px;
      color: #7b7b7b;
      span {
        color: #2356a6;
        font-family: "SF Pro Display Medium";
      }
    }
    .property-label-wrapper {
      display: flex;
      align-items: center;
      .property-label {
        background: #f5f5f5;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
          margin-right: 8px;
        }
        span {
          font-family: "SF Pro Display Regular";
          font-size: 12px;
          line-height: 16px;
          color: #232323;
        }
      }
    }
  }

  .type-and-furnished {
    display: flex;
    align-items: center;
    .category {
      color: #4f4f4f;
      font-family: "SF Pro Display Regular";
      font-size: 15px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.23px;
    }
    svg {
      margin: 0 16px;
    }
    .kondisi-perabotan {
      color: #4f4f4f;
      font-family: "SF Pro Display Regular";
      font-size: 15px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.23px;
    }
  }

  .judul-listing {
    font-family: "SF Pro Display SemiBold";
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.23px;
    color: #232323;
    margin-bottom: 16px;
  }

  .listing-location {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
    .map-pin-logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .location {
      color: #4f4f4f;
      font-family: "SF Pro Display Regular";
      font-size: 15px;
      font-style: normal;
      line-height: 20px;
      letter-spacing: -0.23px;
    }
  }

  .judul-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 375px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    .modal-judul-body {
      height: calc(100% - 116px - 24px - 125px);
      overflow-x: auto;
    }
    .modal-title-street {
      padding: 0 24px;
      margin-top: 24px;
      margin-bottom: 24px;
      .judul-modal-location {
        font-family: "SF Pro Display SemiBold";
        font-size: 22px;
        color: #232323;
        margin-bottom: 16px;
      }
      .street-modal-location {
        font-family: "SF Pro Display Regular";
        font-size: 15px;
        color: #4f4f4f;
      }
    }
    .modal-judul-footer {
      padding: 16px 24px 34px 24px;
      border-top: 1px solid #e9e9e9;
      .modal-judul-exit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        border: 1px solid #232323;
        cursor: pointer;
        .modal-judul-exit-icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          font-family: "SF Pro Display SemiBold";
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.43px;
          color: #232323;
        }
      }
    }
  }
}
