.fasilitas-property-dekstop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 136px;
  margin-bottom: 136px;

  .header-fasilitas-property {
    width: 460px;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #232323;
  }

  .fasilitas-property-content {
    width: 100%;
    max-width: 360px;

    .section-fasilitas-property {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e9e9;

      span {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        color: #232323;

        &:last-child {
          color: #232323;
          font-weight: 600;
        }
      }
    }
  }
}
