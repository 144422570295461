//Global Styles
@import "./global-style/index.scss";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "SF Pro Display";
  src: url(../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
}
@font-face {
  font-family: "Noto Serif Regular";
  src: url(../static/fonts/Noto_Serif/NotoSerif-Regular.ttf) format("truetype");
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "SF Pro Display";
  p{
    margin: 0;
  }
  a{
    text-decoration: none;
  }
}

//Reuseable
@import "./modals/index.scss";

//Pages
@import "./detail-listing-2.0-mobile/index.scss";
@import "./detail-listing-2.0-dekstop/index.scss";