.fasilitas-rumah {
  padding: 0 24px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 40px;
  .fasilitas-rumah-title {
    color: #232323;
    font-family: "SF Pro Display SemiBold";
    font-size: 28px;
    font-style: normal;
    line-height: 34px; 
    letter-spacing: -0.33px;
    margin-bottom: 24px;
  }
  .wrapper-section {
    flex-wrap: wrap;
    display: flex;
    .section-fasilitas-rumah {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      padding: 10px 12px;
      border-radius: 24px;
      background: #f5f5f5;
      margin-right: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-right: 0;
      }
      span {
        color: #232323;
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.23px;
      }
    }
  }
}
