.detail-listing-deskripsi {
  padding: 0 24px;
  padding-bottom: 48px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 40px;
  .header-deskripsi {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.23px;
    color: #232323;
    margin-bottom: 24px;
  }
  .body-deskripsi {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #4f4f4f;
    white-space: pre-line;
    margin-bottom: 8px;

    &.show-read-more {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .footer-deskripsi {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.23px;
    text-decoration-line: underline;
    color: #232323;
    cursor: pointer;
  }

  .deskripsi-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 375px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    .modal-deskripsi-header {
      margin-top: 24px;
      padding: 0 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e9e9e9;
      p {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.23px;
        color: #232323;
      }
    }
    .modal-deskripsi-body {
      height: calc(100% - 53px - 84px - 29px);
      padding: 24px;
      overflow-x: auto;
      .content-deskripsi-modal {
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        color: #4f4f4f;
        white-space: pre-line;
      }
    }
    .modal-deskripsi-footer {
      padding: 16px 24px 34px 24px;
      border-top: 1px solid #e9e9e9;
      .modal-deskripsi-exit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        border: 1px solid #232323;
        cursor: pointer;
        .modal-deskripsi-exit-icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.43px;
          color: #232323;
        }
      }
    }
  }
}
