@font-face {
  font-family: "SF Pro Display";
  src: url(../../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(../../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(../../assets/fonts/SFProDisplay-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display SemiBold";
  src: url(../../assets/fonts/SFProDisplay-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(../../assets/fonts/SFPRODISPLAYBOLD.OTF) format("truetype");
}

@font-face {
  font-family: "Typold";
  src: url(../../assets/fonts/Typold-Extended-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Typold Medium";
  src: url(../../assets/fonts/Typold-Extended-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Typold SemiBold";
  src: url(../../assets/fonts/Typold-Extended-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Typold Bold";
  src: url(../../assets/fonts/Typold-Bold.ttf) format("truetype");
}
