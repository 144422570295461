.main-info-and-image {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 132px;
  max-width: 960px;

  @import "./main-info.scss";
  @import "./image.scss";
}
