.kondisi-rumah {
  padding: 0 24px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f5f5f5;
  .header-kondisi-rumah {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.23px;
    color: #232323;
    margin-bottom: 24px;
  }

  .section-kondisi-rumah {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child{
      margin-bottom: 0;
    }
    span {
      &:first-child {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.23px;
        color: #7b7b7b;
      }
      &:last-child {
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.43px;
        color: #232323;
      }
    }
  }
}
