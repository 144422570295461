.marketing-agent-card-wrapper {
  padding: 0 24px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 16px;
  .info-image-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    .marketing-agent-info,
    .marketing-agent-image {
      width: 50%;
    }

    .marketing-agent-info {
      .header-marketing-agent {
        color: #232323;
        font-family: "SF Pro Display SemiBold";
        font-size: 28px;
        font-style: normal;
        line-height: 34px;
        letter-spacing: -0.33px;
        margin-bottom: 24px;
      }
      .name {
        color: #2356a6;
        font-family: "SF Pro Display SemiBold";
        font-size: 17px;
        font-style: normal;
        line-height: 22px;
        letter-spacing: -0.43px;
        margin-bottom: 8px;
        text-decoration: underline;
        cursor: pointer;
        display: block;
      }
      .company {
        color: #4f4f4f;
        font-family: "SF Pro Display Regular";
        font-size: 15px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.23px;
      }
    }
    .marketing-agent-image {
      height: 312.555px;
      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
