#detail-listing-2-0-dekstop-wrapper{
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .detail-listing-2-0-content-wrapper{
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media (max-width: 1024px) {
      padding: 0 24px;
    }

    @import "./header.scss";
    @import "./main-info-and-image/index.scss";
    @import "./lihat-detail-property.scss";
    @import "./price.scss";
    @import "./deskripsi.scss";
    @import "./spesifikasi-property.scss";
    @import "./kondisi-properti.scss";
    @import "./fasilitas-property-dekstop.scss";
    @import "./fasilitas-umum-dekstop.scss";
    @import "./lokasi-properti.scss";
    @import "./marketing-agent.scss";
  }
}