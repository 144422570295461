.hubungi-ma {
  margin-bottom: 34px;
  padding: 0 24px;
  .button-hubungi-ma {
    border-radius: 2px;
    border: 1px solid #2356a6;
    background: #2356a6;
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    svg {
      margin-right: 8px;
    }
    span {
      color: #fff;
      font-family: "SF Pro Display SemiBold";
      font-size: 17px;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.43px;
    }
  }

  .wrapper-logo-info {
    display: flex;
    svg {
      margin-right: 8px;
      width: 30px;
    }
    span {
      color: #7b7b7b;
      font-family: "SF Pro Display Regular";
      font-size: 11px;
      font-style: normal;
      line-height: 13px;
      letter-spacing: 0.06px;
    }
  }

  .hubungi-wa-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 375px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    .modal-hubungi-wa-header {
      margin-top: 24px;
      padding: 0 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e9e9e9;
      p {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.23px;
        color: #232323;
      }
    }
    .modal-hubungi-wa-body {
      height: calc(100% - 53px - 84px - 29px);
      padding: 24px;
      overflow-x: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .hubungi-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 24px;
        border: 1px solid #232323;
        margin-bottom: 16px;
        width: 100%;
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
          position: absolute;
          left: 24px;
        }
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.43px;
          color: #232323;
        }
      }
    }
    .modal-hubungi-wa-footer {
      padding: 16px 24px 34px 24px;
      border-top: 1px solid #e9e9e9;
      .modal-hubungi-wa-exit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        border: 1px solid #232323;
        cursor: pointer;
        .modal-hubungi-wa-exit-icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          font-weight: 600;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.43px;
          color: #232323;
        }
      }
    }
  }
}
