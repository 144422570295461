.price-and-date {
  .date-wrapper {
    padding: 0 24px;
    padding-bottom: 41px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e9e9e9;

    .date-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: #7b7b7b;
      }
      .date {
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.23px;
        color: #4f4f4f;
      }
    }
  }
  .price-wrapper {
    padding: 0 24px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #e9e9e9;

    .price-price {
      display: flex;
      align-items: start;
      justify-content: space-between;
      margin-bottom: 16px;

      .text-harga {
        font-family: "SF Pro Display SemiBold";
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.43px;
        color: #7b7b7b;
      }

      .price-box {
        .price-box-inner {
          display: flex;
          align-items: center;
          justify-content: end;
        }
        .text-rp {
          color: #7b7b7b;
          text-align: right;
          font-family: "SF Pro Display Regular";
          font-size: 20px;
          font-style: normal;
          line-height: 25px;
          letter-spacing: -0.45px;
        }
        .price {
          font-family: "SF Pro Display Regular";
          font-size: 22px;
          line-height: 28px;
          text-align: right;
          letter-spacing: -0.23px;
          color: #232323;
        }
        svg{
          width: 18px;
          height: 18px;
        }
        .text-rp-down {
          color: #d6d6d6;
          text-align: right;
          font-family: "SF Pro Display Regular";
          font-size: 15px;
          font-style: normal;
          line-height: 25px;
          letter-spacing: -0.45px;
          .price-down {
            font-family: "SF Pro Display Regular";
            font-size: 15px;
            line-height: 28px;
            text-align: right;
            letter-spacing: -0.23px;
          }
        }
      }
    }

    .unit-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: #7b7b7b;
      }
      .unit {
        font-family: "SF Pro Display Medium";
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.23px;
        color: #2356a6;
        text-transform: lowercase;
      }
    }

    .minimum-sewa {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 26px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-family: "SF Pro Display Regular";
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.08px;
        color: #7b7b7b;
      }
      .required {
        color: #af0000;
        font-family: "SF Pro Display SemiBold";
        font-size: 15px;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.23px;
      }
      .unit {
        font-family: "SF Pro Display Regular";
        color: #232323;
        font-size: 17px;
        font-style: normal;
        line-height: 22px;
      }
    }
  }
}
