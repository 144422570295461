.detail-listing-dekstop-price {
  padding-bottom: 160px;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  max-width: 960px;
  margin-bottom: 136px;

  .text-harga {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #4f4f4f;
    margin-bottom: 16px;
    span {
      color: #232323;
    }
  }

  .nilai-harga {
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    color: #a7a7a7;
    span {
      font-weight: 600;
      color: #232323;
    }
  }

  .nilai-harga-turun {
    font-weight: 400;
    font-size: 22px;
    line-height: 60px;
    color: #a7a7a7;
    margin-bottom: 16px;
    s {
      font-weight: 600;
      color: #4f4f4f;
    }

    svg{
      width: 16px;
      height: 16px;
      margin-left: 16px;
    }
  }

  .minimum-sewa {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #4f4f4f;
    margin-top: 40px;

    .asterisk {
      color: #af0000;
    }
    .jangka-sewa {
      color: #2356a6;
    }
  }
}
