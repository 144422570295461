.lihat-detail-property {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 104px;
  max-width: 960px;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #a7a7a7;
    margin-bottom: 16px;
  }
  .arrow-down {
    width: 40px;
    height: 40px;
  }
}