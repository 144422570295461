#detail-listing-2-0-mobile-wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  .detail-listing-2-0-content-wrapper {
    max-width: 375px;
    width: 100%;
    
    @media (min-width: 376px) {
      border: 1px solid #E9E9E9;
    }

    @import "./header.scss";
    @import "./judul.scss";
    @import "./listing-images.scss";
    @import "./price-and-date.scss";
    @import "./spesifikasi-rumah.scss";
    @import "./deskripsi.scss";
    @import "./kondisi-rumah.scss";
    @import "./fasilitas-rumah.scss";
    @import "./perabotan-rumah.scss";
    @import "./fasilitas-perumahan.scss";
    @import "./marketing-agent.scss";
    @import "./hubungi-ma.scss";
  }
}
