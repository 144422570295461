.dekstop-lokasi-properi {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 136px;
  margin-bottom: 136px;

  .label-dan-jalan {
    width: 460px;

    p {
      font-weight: 600;
      font-size: 50px;
      line-height: 60px;
      color: #232323;
      margin-bottom: 16px;
      &:last-child {
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #4f4f4f;
      }
    }
  }

  .lokasi-properti-gmap{
    width: 100%;
    max-width: 360px;
    height: 360px;;
  }
}
