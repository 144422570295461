.perabotan-rumah {
  padding: 0 24px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 40px;
  .header-perabotan-rumah {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.23px;
    color: #232323;
    margin-bottom: 24px;
  }

  .section-perabotan-rumah {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: #232323;
    }
    .green-check {
      width: 24px;
      height: 24px;
    }
  }
}
