.main-info-wrapper {
  margin-right: 16px;
  width: 100%;
  max-width: 460px;

  .category-wrapper {
    padding-bottom: 16px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 48px;
    span {
      padding: 4px 8px;
      font-size: 15px;
      line-height: 20px;
      margin-right: 24px;
      background: #f5f5f5;
    }
  }

  .id-listing-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .id-listing {
      font-size: 15px;
      line-height: 20px;
      color: #7b7b7b;

      .id-hash {
        color: #2356a6;
      }
    }

    .dot-grey {
      margin: 0 16px;
    }

    .furniture-condition {
      font-size: 15px;
      line-height: 20px;
      color: #7b7b7b;
    }
  }

  .detail-listing-title {
    font-size: 34px;
    line-height: 41px;
    color: #232323;
    margin-bottom: 16px;
  }

  .detail-listing-street {
    font-size: 15px;
    line-height: 24px;
    color: #4f4f4f;
    margin-bottom: 48px;
  }

  .detail-listing-tanggal {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    span {
      font-size: 11px;
      line-height: 13px;
      color: #7b7b7b;

      .updated-at {
        color: #232323;
      }
    }
    .dot-grey {
      margin: 0 16px;
    }
  }

  .propertyinfo-wrapper-button {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    button {
      box-sizing: border-box;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #232323;
      display: flex;
      align-items: center;
      padding: 16px 24px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        color: #232323;
        font-size: 17px;
        font-style: normal;
        line-height: 22px; /* 129.412% */
        letter-spacing: -0.43px;
      }
    }
    .tanya-listing {
      border-radius: 2px;
      border: 1px solid #2356a6;
      background: #2356a6;
      margin-left: 24px;
      span {
        color: #fff;
      }
    }
  }

  .propertyinfo-info {
    margin-bottom: 80px;
    p {
      color: #7b7b7b;
      font-size: 11px;
      font-style: normal;
      line-height: 13px; /* 118.182% */
      letter-spacing: 0.06px;
    }
  }
}
