.listing-images {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  .thumbnails {
    width: 100%;
    .main-thumbnail {
      box-shadow: 0px 1.1733332872390747px 2.3466665744781494px 0px rgba(35, 35, 35, 0.06);
      border-radius: 2px;
      border: 1.173px solid #e9e9e9;
      width: 100%;
      height: 327px;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      justify-content: center;
      .main-image,
      video {
        border-radius: 2px;
        border: 1.173px solid #e9e9e9;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
      .fullscreen {
        video {
          object-fit: contain;
        }
      }

      .iphone-btn-play {
        z-index: 5;
        position: absolute;
        width: 50%;
        height: 50%;
        top: 25%;
        cursor: pointer;
      }
      .pause-btn {
        border-radius: 80px;
        background: rgba(35, 35, 35, 0.6);
        display: flex;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        z-index: 3;
        bottom: 19px;
        span {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          line-height: 22px;
          font-family: "SF Pro Display Regular";
        }
        svg {
          width: 20px;
          height: 20px;
          margin-left: 4px;
        }
      }
    }
    .other-thumbnail {
      display: flex;
      justify-content: center;
      .image {
        cursor: pointer;
        margin-right: 16px;
        width: 70px;
        height: 70px;
        border-radius: 2px;
        border: 1.173px solid #e9e9e9;
        box-shadow: 0px 1.1733332872390747px 2.3466665744781494px 0px rgba(35, 35, 35, 0.06);
        img {
          border-radius: 2px;
          border: 1.173px solid #e9e9e9;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:last-child {
          margin-right: 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .lihat-foto-lainnya {
            opacity: 0.8;
            background: #232323;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;

            p {
              color: #fff;
              font-family: "SF Pro Display SemiBold";
              font-size: 11px;
              font-style: normal;
              line-height: 13px;
              letter-spacing: 0.06px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .btn-lihat-foto-detail {
    position: absolute;
    z-index: 1;
    right: 24px;
    bottom: 24px;

    display: flex;
    align-items: center;
    padding: 12px;
    background: #ffffff;
    border: 1px solid #232323;

    .template-img {
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }

    span {
      font-family: "SF Pro Display SemiBold";
      font-size: 12px;
      line-height: 16px;
      color: #232323;
    }
  }

  .images-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 375px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    .modal-images-header {
      margin-top: 24px;
      padding: 0 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-family: "SF Pro Display";
        font-style: normal;
        font-family: "SF Pro Display SemiBold";
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.23px;
        color: #232323;
      }

      .modal-image-exit-icon {
        width: 34px;
        height: 34px;
        margin-right: 8px;
        cursor: pointer;
        border: 1px solid #232323;
        border-radius: 800px;
        padding: 4px;
      }
    }
    .modal-images-body {
      height: calc(100% - 53px - 84px - 29px);
      padding: 24px;
      overflow-x: auto;
      .images-wrapper {
        margin-bottom: 16px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .video-modal-content {
    width: 100vw;
    height: 100vh;
    max-width: 375px;
    background: #ffffff;
    border: 1px solid #e9e9e9;

    .modal-images-header {
      margin-top: 24px;
      padding: 0 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .modal-image-exit-icon {
        width: 34px;
        height: 34px;
        margin-right: 8px;
        cursor: pointer;
        border: 1px solid #232323;
        border-radius: 800px;
        padding: 4px;
      }
    }

    .modal-video-player {
      width: 100% !important;
      max-height: 327px;
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 375px) {
  .listing-images {
    .thumbnails {
      .other-thumbnail {
        .image {
          cursor: pointer;
          width: calc(((100vw - 48px) / 4) - 14px);
          height: calc(((100vw - 48px) / 4) - 14px);
        }
      }
    }
  }
}
